import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import "./../node_modules/jam-icons/js/jam.min";
import "./../node_modules/jam-icons/css/jam.min.css";
import "./assets/scss/main.scss";
import "primevue/resources/themes/nova-light/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "vue-toast-notification/dist/theme-default.css";
import "@flaticon/flaticon-uicons/css/all/all.css";

import Vuelidate from "vuelidate";
import VuePageTransition from "vue-page-transition";
import VueToast from "vue-toast-notification";
import CKEditor from "@ckeditor/ckeditor5-vue";
import Vuex from "vuex";
import Ripple from "./components/CustomComponents/ripple/Ripple";
import Fragment from 'vue-fragment'

import NotificationsProvider from "./providers/NotificationsProvider"; // Adjust the path

Vue.use(VueToast, { position: "bottom" });
Vue.use(VuePageTransition);
Vue.use(Vuelidate);
Vue.use(CKEditor); // CKEditor online builder: https://ckeditor.com/ckeditor-5/online-builder/
Vue.use(Vuex);
Vue.use(Fragment);
Vue.directive("ripple", Ripple);

export const store = new Vuex.Store({
    state: {
        filters: null,
        paginator: null,
        prescricaoEmitida: false,
        prescricaoRemovida: false,
        plataformaMemedPronta: false,
        plataformaMemedFechada: false,
    },
    mutations: {
        setFilters(state, payload) {
            state.filters = payload;
        },
        setPaginator(state, payload) {
            state.paginator = payload;
        },
        clearFilters(state) {
            state.filters = null;
            state.paginator = null;
        },
        setPrescricaoEmitida(state, payload) {
            state.prescricaoEmitida = payload;
        },
        setPrescricaoRemovida(state, payload) {
            state.prescricaoRemovida = payload;
        },
        setPlataformaMemedPronta(state, payload) {
            state.plataformaMemedPronta = payload;
        },
        setPlataformaMemedFechada(state, payload) {
            state.plataformaMemedFechada = payload;
        },
    },
    getters: {
        getPrescricaoEmitida: (state) => {
            return state.prescricaoEmitida;
        },
        getPrescricaoRemovida: (state) => {
            return state.prescricaoRemovida;
        },
        getPlataformaMemedPronta: (state) => {
            return state.plataformaMemedPronta;
        },
        getPlataformaMemedFechada: (state) => {
            return state.plataformaMemedFechada;
        },
    },
});

import VueCurrencyInput from "vue-currency-input";
Vue.use(VueCurrencyInput, { globalOptions: { currency: null } });

Vue.config.productionTip = false;

const globals = {
    methods: {
        formatCPF(val, applyMask) {
            if (!val) return "";
            let onlyNumbers = val.match(/\d+/g).join("");
            return !applyMask
                ? onlyNumbers
                : onlyNumbers.replace(
                      /(\d{3})(\d{3})(\d{3})(\d{2})/,
                      "$1.$2.$3-$4"
                  );
        },
        formatPrice(val) {
            return new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
            }).format(val || 0);
        },
        applyTelefoneMask(telefone) {
            if (telefone[0] == "+") telefone = telefone.substr(1);
            if (telefone.substr(0, 2) == "55") telefone = telefone.substr(2);
            let regex =
                telefone.length == 10
                    ? /(\d{2})(\d{4})(\d{4})/
                    : /(\d{2})(\d{5})(\d{4})/;
            return telefone.replace(regex, "($1) $2-$3");
        },
        padStart(str, len = 0, char = "0") {
            str = str.toString();
            while (str.length < len) str = `${char}${str}`;
            return str;
        },
        formatCNPJ(cnpj) {
            cnpj = String(cnpj).replace(/\D/g, '');
            return (cnpj.length === 14) 
                ? cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5') 
                : cnpj
        }
    },
};

new Vue({
    mixins: [globals],
    router,
    store,
    render: (h) => h(NotificationsProvider, [h(App)]),
    created: () => {
        const html = document.documentElement; // returns the html tag
        html.removeAttribute("lang");
        html.setAttribute("translate", "no");
    },
}).$mount("#app");

<!-- eslint-disable vue/valid-template-root -->
<template lang="pug">
    fragment
        slot
</template>

<script>
import { firebase_db } from "../firebase"; // Adjust the path to your Firebase setup
import { 
    collection, 
    doc, 
    deleteDoc,
    onSnapshot, 
    query, 
    orderBy, 
    where,
    or,
    and
} from "firebase/firestore";
import { COLLECTION_GESTOR_NOTIFICACOES } from './utils/fb_utils'
import { Fragment } from 'vue-fragment'
import configs from "../middleware/configs";

export default {
    name: "NotificationsProvider",
    data() {
        return {
            messages: [],
            unreadCount: 0,
            firstLoading: true,
            dialogNotificacoes: false,
            usuario: 0
        };
    },
    props: {
        ie_notifica:{
            type: Boolean,
            default: true
        }
    },
    created() {
        this.fetchNotifications();
    },
    components: { Fragment },
    methods: {
        fetchNotifications() {
            if (configs.env == 'prod'){

                this.usuario = JSON.parse(localStorage.getItem("usuario"));
    
                if (this.usuario && this.usuario.accessToken && this.usuario.sessionValid){
                    const unsubscribe = onSnapshot(
                        this.getBaseQueryFirebase(),
                        (snapshot) => {
                            if (!this.firstLoading) {
                                const unreadMessages = this.getNaoLidas(snapshot)
                                if (this.$props.ie_notifica){
                                    this.notificarUsuario(unreadMessages)
                                }
                                this.setMessagesArray(snapshot)
                                this.getTotalNaoLidas()
                            }else{
                                this.setMessagesArray(snapshot)
                                this.unreadCount =  this.messages.length;
                                this.getTotalNaoLidas()
                                this.firstLoading = false
                            }
                        }
                    );
                    this.$once("hook:beforeDestroy", unsubscribe);
                }
            }
        },
        setMessagesArray(snapshot){
            this.messages = snapshot.docs.filter((doc) => {
                return this.callBackFilter(doc, this.usuario)
            });
            this.messages = this.messages.map(doc => ({
                "id": doc.data().id,
                "message": doc.data().message,
                "dt_criado": doc.data().dt_criado,
                "read": doc.data().read,
                "type": doc.data().type
            }));
            this.messages.sort((a, b) => new Date(b.dt_criado) - new Date(a.dt_criado))
            this.unreadCount = this.messages.length
        },
        callBackFilter(doc){
            let data = doc.data();
            if (!data?.groups_allowed) {
                if (this.onlyWhoExec(data)) {
                    return true;
                }
            } else {
                const permissoes = this.isGroupAllowd(data)
                if (permissoes.isAllowed || permissoes.quemExec) {
                    return true;
                }
            }
            
            return false;
        },
        notificarUsuario(naoLidas){
            naoLidas.forEach(msg => {
                let data = msg.data();
                if (!data?.groups_allowed) {
                    if (this.onlyWhoExec(data)){
                        // Este IF é para enviar uma notificação para o usuário que executou alguma ação que chamou a função do firestore no back.
                        if (this.isHomolog(data)) {
                            this.chamarToast(data)
                        }
                    }
                }else{
                    const permissoes = this.isGroupAllowd(data)

                    if ((permissoes.isAllowed || permissoes.quemExec) && this.isHomolog(data) ) {
                        this.chamarToast(data)
                    }
                }
            })
        },
        onlyWhoExec(data){
            const quemExec = data?.apenas_para_quem_executou && Number(data.cd_usuario) === this.usuario.id
            return quemExec
        },
        isHomolog(data){
            const msg_homolog = data.homolog // true
            const isHomolog = configs.env === 'homolog'
            return msg_homolog === isHomolog
        },
        isGroupAllowd(data){
            const groups_allowed_to_receive_notification = data.groups_allowed
            const isAllowed = this.usuario && this.usuario.grupos.find(g => groups_allowed_to_receive_notification.includes(g.id))
            const quemExec = this.onlyWhoExec(data)

            return {
                "isAllowed": isAllowed,
                "quemExec": quemExec
            }
        },
        chamarToast(msgData){
            this.$toast.open({
                message: msgData.message,
                type: msgData.type || 'warning',
                position: msgData.position || 'top-left',
                dismissible: msgData.dismissible || true,
                duration: msgData.duration || 0,
                onClick: () => {
                    if (msgData.redirect_url) this.$router.push(msgData.redirect_url)
                },
            })
            const audio = new Audio(require('../assets/audio/notification.ogg'))
            audio.play()
        },
        getNaoLidas(snapshot){
            const naoLidas = snapshot.docs.filter(doc => {
                const docWasAlreadyRed = this.messages.some(msg => msg.id === doc.id)
                return !docWasAlreadyRed
            })
            return naoLidas
        },
        getBaseQueryFirebase(){
            const dataAtual = new Date();
            const seteDiasAtras = new Date();
            console.log(orderBy)
            seteDiasAtras.setDate(dataAtual.getDate() - 3);
            const queryUser = query(
                collection(firebase_db, COLLECTION_GESTOR_NOTIFICACOES),
                and(
                    where("dt_criado", ">=", this.formatDate(seteDiasAtras)),
                    or(
                        where("cd_usuario", "==", this.usuario.id),
                        where("groups_allowed", "array-contains-any", [30])
                    ),
                )
            );
            return queryUser
        },
        formatDate(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');

            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        },
        resetUnreadCount() {
            this.unreadCount = 0;
        },
        async markAllAsRead() {
            await Promise.all(
                this.messages.map(async (msg) => {
                    await deleteDoc(
                        doc(
                            firebase_db,
                            COLLECTION_GESTOR_NOTIFICACOES,
                            msg.id
                        )
                    );
                })
            );
            this.messages = [];
        },
        async markOneMessageAsRead(id) {
            await deleteDoc(
                doc(
                    firebase_db,
                    COLLECTION_GESTOR_NOTIFICACOES,
                    id
                )
            );

            this.messages = this.messages.filter((msg) => msg.id !== id);
        },
        getTotalNaoLidas(){
            this.$emit('notificacoes', this.messages);
            this.$emit('totalNaoLidas', this.unreadCount);
        }
    },
    provide() {
        const exposedProps = ['messages', 'unreadCount']; // <--- notice this list of properties to be exposed as proxy for realtime changes
        const providedState = new Proxy(this.$data, {
            get(target, prop) {
                return exposedProps.includes(prop) ? Reflect.get(...arguments) : undefined;
            },
            ownKeys(target) {
                return Reflect.ownKeys(target).filter(key => exposedProps.includes(key));
            }
        });
        return {
            providedState,
            resetUnreadCount: this.resetUnreadCount,
            markAllAsRead: this.markAllAsRead,
            markOneMessageAsRead: this.markOneMessageAsRead
        }
    },
};
</script>